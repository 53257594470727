.feedback {
    /* font-family: 'Sovmod'; */
    display: flex;
    min-width: 100%;
    min-height: 300px;
    overflow-x: auto;
    margin-left: -30px;
    margin-right: -30px;
}


.feedback__card {
    background-color: rgb(240, 240, 240);
    min-width: 230px;
    margin: 5px;
    border-radius: 5px;
      
}
.feedback__photo {
    border-radius: 5px;
    margin: 3px;
}
.feedback__text {
    font-family: 'Inter'; 
    margin: 3px;
    padding: 3px;
    font-size: 11px;
    color: rgb(55, 55, 55);
    font-family: inter;
    font-style: italic;
    text-align: left;
}
.feedback__about {
    font-family: 'SovMod';
    font-size: 18px;
    color: rgb(55, 55, 55);
    text-align: center;
}