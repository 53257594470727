.Popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw; 
    background-color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
   
}

.Popup.active {
    opacity: 1;
    pointer-events: all;
    z-index: +100;
}


.Popup__content {
    padding: 20px ;
    border-radius: 12px;
    background-color: white;
    width: 50vw;
    transform: scale(0.5);
    transition: 0.3s all;
    max-width: 600px;
}
.Popup__content.active {
    transform: scale(1);
}
.Popup__btn {
    display: flex;
    margin: auto;
    align-items: center;
    font-family: "SovMod";
    color:blue;
    border-color: blue;
    border-radius: 12px;
    /* height: 50px; */
    max-width: 200px;
    font-size: 16px;
}
.Popup__btn :hover{
    cursor: pointer;
}