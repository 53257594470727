.footer {
    width: 100%;
    display: flex;
   
    font-family: 'Inter';
    font-size: 30px;
    font-weight: normal;
    color: rgb(55, 55, 55); 
    /* padding-bottom: 10px; */
    margin-top: 20px; 
    text-align: center;
    background-color: #060606;
}
.footer_logo {
    height: 20px;
    margin-right: 20px;
}
.footer_anchor {
    display: flex;
   
}
.footer_copyright {
    margin-left: 10px;
    color: #ffffff;
    font-size: 16px;
}