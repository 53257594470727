.profile {
  font-family: 'SovMod';
  max-width: 80%;
  margin-top: 20px;
  margin: auto;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  color: rgb(60, 60, 60); 
  text-align: justify;
  
}
.profile__photo {

 margin: auto;
 border-radius: 10px;
 width: 150px;
 
 box-shadow: 5px 5px 5px gray;

}
.profile__story {
  /* font-family: 'SovMod'; */
  font-size: 12px;
  font-family: inter;
  font-weight: normal;
 /* line-height: 1.5;*/
}
.profile__citata {
  max-width: 700px;
  font-size: 30px;
  /* font-family: 'SovMod'; */
  margin: auto; 
  padding-bottom: 70px;
  text-align: center; 
  z-index: -1;
  line-height: 2;
}
.profile__icon {
  width: 50px;
  height: 50px;
  margin: auto;
}


@media screen and (max-width: 576px) {
  .profile {
    flex-direction: column;
  }
  .profile_photo {
    margin: auto;
  }
  .profile__citata {
    font-size: 20px;
  }
  
}
.gismeteo {
  margin: auto;
}
