.whatsapp {
    width: 40px;
    /* margin-right: 20px; */
    position:sticky;
    left: 99%;
    bottom: 30px;
   /* margin-top: 5px; */
  }

  @media screen and (max-width: 425px) {
    .whatsapp {
    width: 40px;
    } 
  }